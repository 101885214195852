import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table className="table">
	<tr>
		<th>Description</th>
		<th>Date</th>
		<th>Event</th>
		<th>Location</th>
	</tr>
	<tr>
		<td> TBA</td>
		<td>September 2019</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Workshop: Building RESTful APIs with ASP.NET Core and C#</td>
		<td>May 2019</td>
		<td><a href="https://ndcminnesota.com/">NDC Minnesota</a></td>
		<td>Minneapolis, MN, USA</td>
	</tr>
	<tr>
		<td> Real World Expression Trees in C#</td>
		<td>May 2019</td>
		<td><a href="https://ndcminnesota.com/">NDC Minnesota</a></td>
		<td>Minneapolis, MN, USA</td>
	</tr>
	<tr>
		<td> Real World Expression Trees in C#</td>
		<td>February 2019</td>
		<td><a href="https://ndcporto.com/">NDC Porto</a></td>
		<td>Porto, Portugal</td>
	</tr>
	<tr>
		<td> Workshop: Building Single Page Apps Using React and ES6</td>
		<td>September 2018</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td> The Hello World Show LIVE</td>
		<td>September 2018</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td> Using Immutable Data Structures in C#</td>
		<td>September 2018</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Your JavaScript Needs Types</td>
		<td>July 2018</td>
		<td>KCDC</td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td> Versioning and Maintaining Your REST API in ASP.NET Core</td>
		<td>July 2018</td>
		<td>KCDC</td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td>Deconstructing TypeScript's Type System</td>
		<td>June 2018</td>
		<td>NDC Oslo</td>
		<td>Oslo, Norway</td>
	</tr>
	<tr>
		<td>Using C# Expression Trees in the Real World</td>
		<td>March 2018</td>
		<td>St. Louis .NET User Group</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>The Hello World Show LIVE (<a href="https://www.youtube.com/watch?v=66fcoX7kt7Y">video</a>)</td>
		<td>January 2018</td>
		<td>NDC London</td>
		<td>London, England</td>
	</tr>
	<tr>
		<td>Security and Authentication Strategies for ASP.NET Core</td>
		<td>December 2017</td>
		<td><a href="https://confoo.ca/en">ConFoo.ca</a></td>
		<td>Vancouver, Canada</td>
	</tr>
	<tr>
		<td>Using C# Expression Trees in the Real World</td>
		<td>December 2017</td>
		<td><a href="https://confoo.ca/en">ConFoo.ca</a></td>
		<td>Vancouver, Canada</td>
	</tr>
	<tr>
		<td>Security and Authentication Strategies for ASP.NET Core</td>
		<td>October 2017</td>
		<td><a href="https://devupconf.org">DevUp</a></td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Your JavaScript Needs Types</td>
		<td>October 2017</td>
		<td><a href="https://devupconf.org">DevUp</a></td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Workshop - Building Web Apps with ASP.NET Core (<a href="https://github.com/schneidenbach/aspnetcore-workshop">slides/code</a>)</td>
		<td>September 2017</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>An Opinionated, Maintainable REST API Architecture for ASP.NET Core (<a href="https://schneids.net/never-resting-restful-api-best-practices-using-asp-net-web-api/">slides/info</a>)</td>
		<td>September 2017</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Your JavaScript Needs Types (<a href="https://github.com/schneidenbach/YourJavaScriptNeedsTypes">slides/info</a>)</td>
		<td>September 2017</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Using C# Expression Trees in the Real World (<a href="https://github.com/schneidenbach/CSharpExpressionTreesInTheRealWorld">slides/info</a>)</td>
		<td>September 2017</td>
		<td>KGD.NET User Group</td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Deconstructing TypeScript's Type System (<a href="https://schneids.net/deconstructing-typescripts-type-system/">slides/info</a>)</td>
		<td>August 2017</td>
		<td><a href="http://www.kcdc.info/">Kansas City Developer Conference</a></td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API (<a href="https://schneids.net/never-resting-restful-api-best-practices-using-asp-net-web-api/">slides/info</a>)</td>
		<td>August 2017</td>
		<td><a href="http://www.kcdc.info/">Kansas City Developer Conference</a></td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td>Deconstructing TypeScript's Type System</td>
		<td>July 2017</td>
		<td>Detroit.Code()</td>
		<td>Detroit, MI</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>July 2017</td>
		<td>Detroit.Code()</td>
		<td>Detroit, MI</td>
	</tr>
	<tr>
		<td>An Opinionated, Maintainable REST API Architecture for ASP.NET Core</td>
		<td>June 2017</td>
		<td>NDC Oslo</td>
		<td>Oslo, Norway</td>
	</tr>
				<tr>
		<td> An Opinionated, Maintainable REST API Architecture for ASP.NET Core </td>
		<td>June 2017</td>
		<td>Code PaLOUsa</td>
		<td>Louisville, KY</td>
	</tr>
				<tr>
		<td>Workshop - Building Web Apps with ASP.NET Core 2.0</td>
		<td>May 2017</td>
		<td>Nebraska.Code()</td>
		<td>Lincoln, NE</td>
	</tr>
	<tr>
		<td> An Opinionated, Maintainable REST API Architecture for ASP.NET Core </td>
		<td>May 2017</td>
		<td>Nebraska.Code()</td>
		<td>Lincoln, NE</td>
	</tr>
	<tr>
		<td> Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>May 2017</td>
		<td>Nebraska.Code()</td>
		<td>Lincoln, NE</td>
	</tr>
	<tr>
		<td>Deconstructing TypeScript's Type System</td>
		<td>April 2017</td>
		<td>Chicago Code Camp</td>
		<td>Chicago, IL</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>January 2017</td>
		<td>NDC London</td>
		<td>London, England</td>
	</tr>
	<tr>
		<td>Functional Web Programming using Elm</td>
		<td>January 2017</td>
		<td>CodeMash</td>
		<td>Sandusky, OH</td>
	</tr>
	<tr>
		<td>Getting Started with Angular 2 and TypeScript</td>
		<td>October 2016</td>
		<td>DevUp</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>October 2016</td>
		<td>DevUp</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Getting Started with Angular 2 and TypeScript</td>
		<td>October 2016</td>
		<td>New York Code Camp</td>
		<td>New York, NY</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>October 2016</td>
		<td>New York Code Camp</td>
		<td>New York, NY</td>
	</tr>
	<tr>
		<td>Getting Started with Angular 2 and TypeScript</td>
		<td>September 2016</td>
		<td>Heartland Developer Conference</td>
		<td>Omaha, NE</td>
	</tr>
	<tr>
		<td>Functional Web Programming using Elm</td>
		<td>September 2016</td>
		<td>Heartland Developer Conference</td>
		<td>Omaha, NE</td>
	</tr>
	<tr>
		<td>ASP.NET Core – What You Need To Know</td>
		<td>August 2016</td>
		<td>dotNetConf.local</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>August 2016</td>
		<td>Music City Code</td>
		<td>Nashville, TN</td>
	</tr>
	<tr>
		<td>Getting Started with Angular 2 and TypeScript</td>
		<td>August 2016</td>
		<td>Music City Code</td>
		<td>Nashville, TN</td>
	</tr>
	<tr>
		<td>Building Single Page Apps using Angular 2, ASP.NET Core, and TypeScript</td>
		<td>August 2016</td>
		<td>Music City Code</td>
		<td>Nashville, TN</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>July 2016</td>
		<td>St. Louis .NET User Group</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Getting Started with Angular 2 and TypeScript</td>
		<td>June 2016</td>
		<td>Kansas City Developer Conference</td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>June 2016</td>
		<td>Kansas City Developer Conference</td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td>Building Single Page Apps using Angular 2, ASP.NET Core, and TypeScript</td>
		<td>June 2016</td>
		<td>Kansas City Developer Conference</td>
		<td>Kansas City, MO</td>
	</tr>
	<tr>
		<td>Getting Started with Angular 2 and TypeScript</td>
		<td>May 2016</td>
		<td>Nebraska.Code()</td>
		<td>Lincoln, NE</td>
	</tr>
	<tr>
		<td>ASP.NET Core – What You Need To Know</td>
		<td>May 2016</td>
		<td>Nebraska.Code()</td>
		<td>Lincoln, NE</td>
	</tr>
	<tr>
		<td>Building Single Page Apps using Angular 2, ASP.NET Core, and TypeScript</td>
		<td>May 2016</td>
		<td>Nebraska.Code()</td>
		<td>Lincoln, NE</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API</td>
		<td>April 2016</td>
		<td>Chicago Code Camp</td>
		<td>Chicago, IL</td>
	</tr>
	<tr>
		<td>Building Real-Time Mobile Apps using Xamarin and SignalR</td>
		<td>November 2015</td>
		<td>St. Louis Days of .NET/DevUp</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>Building Real-Time Mobile Apps using Xamarin and SignalR</td>
		<td>August 2015</td>
		<td>ThatConference</td>
		<td>Wisconsin Dells, WI</td>
	</tr>
	<tr>
		<td>What’s New in ASP.NET 5</td>
		<td>June 2015</td>
		<td>St. Louis .NET User Group</td>
		<td>St. Louis, MO</td>
	</tr>
	<tr>
		<td>What’s New in ASP.NET 5</td>
		<td>April 2015</td>
		<td>Microsoft Store</td>
		<td>St. Louis, MO</td>
	</tr>
    </table>
    <h3>{`Past talks`}</h3>
    <h4><a parentName="h4" {...{
        "href": "http://schneids.net/building-single-page-apps-angular-2-asp-net-core-typescript/"
      }}>{`Building Single Page Apps with Angular 2, ASP.NET Core and TypeScript`}</a></h4>
    <p>{`The web world has become increasingly more complex over the past few years. The demand for fast, fluid single page applications (or SPAs) has gone up, along with the seemingly endless number of frameworks used to build them. The most popular among them, Angular 1, took the SPA world by storm seven years ago. Since then, it’s been adopted by many developers and firms looking to build rich, feature-fill applications for the web and beyond.`}</p>
    <p>{`Angular 2 looks to continue that trend with a library that is more performant, lighter, and easier to use. We’ll learn about components, directives, and services as well as the new Angular 2 syntax. We’ll be building all of these using TypeScript, Microsoft’s open source language that the Angular 2 team has adopted as their language of choice for developing Angular 2 applications.`}</p>
    <p>{`We’ll also take an opportunity to explore ASP.NET Core, Microsoft’s latest web offering. This open source, lightweight framework runs across many platforms and is a perfect back end complement to Angular 1 or 2. We’ll explore setting up a new project with Angular 2 as well as setting up controllers, models, and views. For current Web API 2 users, we’ll also briefly go over some of the major differences between ASP.NET 4.6 and ASP.NET Core.`}</p>
    <h4><a parentName="h4" {...{
        "href": "http://schneids.net/asp-net-core-need-know-2/"
      }}>{`ASP.NET Core – What You Need To Know`}</a></h4>
    <p>{`Microsoft has built a rich ecosystem around the various flavors of ASP.NET – MVC, Web API and even WebForms have a place in the modern web development world. So what the heck is up with ASP.NET Core anyways? Microsoft has flipped the ASP.NET world on its head with this fast, cross-platform web framework, designed from the ground up with developers from all walks of life in mind – and the change isn’t as scary as you think.`}</p>
    <p>{`In this session, we’ll learn about ASP.NET Core and its design philosophies. We’ll learn how to leverage several popular development tools frequently used by developers outside of the ASP.NET ecosystem, including Gulp, Bower, Node and NPM, to build and run our application. Finally, we’ll use Visual Studio Code to create and run our very first ASP.NET Core project (on a Mac no less!)`}</p>
    <p>{`For current ASP.NET developers, we’ll also touch on the portability of your current ASP.NET code.`}</p>
    <h4><a parentName="h4" {...{
        "href": "http://angular2.schneids.net"
      }}>{`Getting Started with Angular 2 and TypeScript`}</a></h4>
    <p>{`The web world has become increasingly more complex over the past few years. The demand for fast, fluid single page applications (or SPAs) has gone up, along with the seemingly endless number of frameworks used to build them. The most popular among them, Angular 1, took the SPA world by storm seven years ago. Since then, it’s been adopted by many developers and firms looking to build rich, feature-fill applications for the web, mobile, and beyond.`}</p>
    <p>{`Angular 2 looks to continue that trend with a library that is more performant, lighter, and easier to use. In this session, we’ll learn about components, directives, services, and dependency injection, as well as the new Angular 2 syntax. We’ll also learn about TypeScript, Microsoft’s open source superset of JavaScript that enables fast, fluid, fun, and safe development. Learn about some of the features of TypeScript that enable us to rapidly develop and easily maintain Angular 2 applications with ease, including classes, decorators, and compile-time type checking.`}</p>
    <h4>{`Real-Time, Cross-Platform Mobile Apps using Xamarin and SignalR`}</h4>
    <p>{`Refreshing is out, real time data is in.  No longer is it good enough to expect your users to pull to refresh or reload an app to get the latest data from your service.  Modern frameworks have made it much easier to add real time functionality to anything, from web pages to mobile apps.  SignalR, ASP.NET’s real-time data framework, is typically used for real time communication on the web, but that’s just the tip of the iceberg.  Learn to leverage this popular and easy-to-use framework to create apps that work real time.`}</p>
    <p>{`Speaking of apps, what better framework to use than Xamarin?  Cross-platform C# mobile development is a .NET engineer’s dream, and Xamarin has made it even easier with Xamarin.Forms, a framework that can be used to create applications for all three major mobile platforms – Android, iOS and Windows Phone.  Using the power of Xamarin and SignalR, you can create fast, maintainable mobile apps with real time data – and do it fast!`}</p>
    <h4>{`Doing the Impossible: Friction-Free Web Development using Elm`}</h4>
    <p>{`How can you bring true functional programming into the web world, be guaranteed to never have runtime errors, AND transpile down to JavaScript so you can run it anywhere? No, it's not a pipe dream - it's Elm. Elm describes itself as a direct competitor to React, but it’s so much more – it’s a language as well as a set of tools for building scalable web applications in a concise, maintainable way.`}</p>
    <p>{`In this session, we'll explore the Elm programming language and how its functional roots will help you write code that guarantees zero runtime errors and zero surprises. Attendees will learn how to leverage The Elm Architecture and the model-update-view pattern to structure their web applications. Finally, attendees will learn how to get started with the Elm tooling, including the compiler and command line interface.`}</p>
    <h4>{`What’s New in ASP.NET 5`}</h4>
    <p>{`A presentation and demo of some of the new features in ASP.NET 5.`}</p>
    <p>{`Microsoft Store in the St. Louis Galleria, 2015`}<br parentName="p"></br>{`
`}{`St. Louis .NET User Group Meeting, 2015`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      